// Colors

export const colorGreen = '#209E20';
export const colorDarkGreen = '#209E20';
export const colorLightGreen = '#86D875';
export const colorLighterGreen = '#7AC943';
export const colorLightestGreen = '#50FF2E';

export const colorBlue = '#86D875';
export const colorDarkBlue = '#246EA5';
export const colorDarkerBlue = '#1D5E9C';
export const colorLightBlue = '#00a3e9';
export const colorLighterBlue = '#51C0FF';
export const colorLightestBlue = '#D7E5EC';

export const colorBlack = '#000000';
export const colorLightBlack = '#4B4B4B';
export const colorLighterBlack = '#666666';

export const colorGray = 'gray';
export const colorLightGray = '#dddddd';
export const colorDarkGray = '#989898';
export const colorWhite = '#ffffff';
export const colorDarkWhite = '#F7F7F7';

export const colorYellow = "#fcc900";

export const gradientGreenToBlue =
  'linear-gradient(180deg, rgba(80,255,46,1) 0%, rgba(63,169,245,1) 86.4%)';
export const gradientBlueToDarkBlue =
  'linear-gradient(180deg, rgba(63,169,245,1) 0%, rgba(29,94,156,0.83) 100%)';
export const gradientLightBlueToBlue =
  'linear-gradient(180deg, rgba(81,192,255,1) 0%, rgba(43,128,175,1) 100%)';
export const gradientLightGreenToGreen =
  'linear-gradient(180deg, rgba(80,255,46,1) 0%, rgba(78,250,45,1) 18.7%, rgba(32,158,32,1) 100%)';

// Font Sizes

export const fontSizeHeading1 = '48px';
export const fontSizeHeading2 = '36px';
export const fontSizeHeading3 = '28px';

export const fontSizeButton1 = '24px';
export const fontSizeButton2 = '18px';

export const fontSizeLg = '24px';
export const fontSizeMd = '20px';
export const fontSize = '16px';
export const fontSizeSm = '13px';
export const fontSizeXs = '12px';
export const fontSizeXxs = '10px';

export const fontSizeMenu = '18px';
export const fontSizeSearch = '23px';