import styled from 'styled-components'
import PropTypes from 'prop-types'

const Align = styled.div`
    text-align: ${props => props.position}   
`

Align.propTypes = {
    position: PropTypes.string
}

Align.defaultProps = {
    position: 'left'
}

export default Align;