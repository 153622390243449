import React from 'react';

const Img = props => {
    
    return (
        <>
            <img src={props.src} className={props.className} alt=""></img>
        </>
    )
}

export default Img
