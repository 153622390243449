import styled, {css} from 'styled-components'

const Row = styled.div.attrs(props => ({
    className: props.cname
}))`
    display: flex;
    flex-wrap: ${props => props.nowrap ? 'nowrap' : 'wrap'};
    flex-direction: ${props => props.direction ? props.direction : 'initial'};
    justify-content: ${props => props.hlign ? props.hlign : 'initial'};
    ${props => props.vcenter && css`
        align-items: center;
    `}
    height: ${props => props.height}
`

Row.defaultProps = {
    height: 'auto'
}

const Col = styled.div.attrs(props => ({
    className: props.cname
}))`
    width: ${props => props.width ? props.width : '100%'};
    ${props => props.wpadding && css`
        padding: ${props.wpadding};
    `}
`

export { Row, Col };