import styled, { css } from "styled-components"

const Btn = styled.button`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border: 0;
  position: relative;
  display: inline-block;
  ${props =>
    !props.primary &&
    css`
      color: #191a1a;
      line-height: 30px;
      background-color: transparent;
      padding: 0;
    `}
  ${props =>
    props.primary &&
    css`
      background: #00a3e9;
      color: #fff;
      height: 40px;
      padding: 0 30px;
      border-radius: 4px;
      &:hover {
          background-color: #0090ce;
      }
    `};
`

export default Btn
