import styled from 'styled-components'
import { colorLightGray, colorDarkGray } from '../Variables/Variables'

const Footer = styled.div`
    border-top: 1px solid ${ colorLightGray };
    padding: 20px 0 15px;
    p {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${ colorDarkGray }
    }
`

export default Footer