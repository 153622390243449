import styled, {css} from 'styled-components';
import { colorLightGray } from './Variables/Variables';
import { media } from './Utilities/Media';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  @media ${media.md} {
    padding: 0 50px;
  }
  @media ${media.lg} {
    padding: 0;
  }
`;

const CustomWrapper = styled(Wrapper)`
  margin: ${props => props.margin};
  max-width: ${props => props.full && '100%'};
  padding: ${props => props.padding};
  ${props => props.wborder && css`
    border-top: 1px solid ${ colorLightGray };
  `}
`

export {Wrapper, CustomWrapper};
