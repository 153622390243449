/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./Partials/Header"
import Footer from "./Partials/Footer"
import Align from "./Layout/Align"
import { useSpring, animated, config } from 'react-spring'
import { media } from "./Utilities/Media"
import Styled from 'styled-components'
import '../styles/style.css';

const Main = Styled.main`
  @media ${media.md} {
    margin-top: 105px;
  }
`
const Layout = ({ children }) => {
  const props = useSpring({from: {opacity: 0}, opacity: 1, config: {duration: 500}});
  return (
    <animated.div style={props}>
      <Header/> 
      <Main>{children}</Main>
      <Footer>
        <Align position={'center'}>
          <p>© GSS Lab Inc. { new Date().getFullYear() } / All Rights Reserved</p>
        </Align>
      </Footer>
    </animated.div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
