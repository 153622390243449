import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import { Row, Col } from "../Layout/Grid"
import {Wrapper} from "../Wrapper"
import styled from 'styled-components'
import Img from "../Img"
import Logo from "../../assets/images/gss-logo.png"
import Align from "../Layout/Align"
import { colorLightGray, colorWhite } from '../Variables/Variables'
import { media } from "../Utilities/Media"

const HeaderStyle = styled.header`
  border-bottom: 1px solid ${ colorLightGray };
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  height: 105px;
  background-color: ${ colorWhite };
  ul {
    padding-top: 12px;
  }
  ul::after {
    content: '';
    display: table;
    clear: both;
  }
  li{
    margin-right: 58px;
    font-size: 16px;
    float: left;
    font-weight: 700;
    letter-spacing: 0.025em;
    &:last-child {
      margin-right: 0;
    }
  }
  .header-img {
    width: 70px;
    height: auto;
  }
`

const HeaderWrapper = styled(Wrapper)`
  height: 100%;
`

const HeaderCol = styled(Col)`
  &:first-of-type,&:last-of-type {
   @media ${media.sm} {
     display: none;
   }
  }

  &:nth-of-type(2) {
    @media ${media.sm} {
      > div {
        text-align: left;
      }
    }
  }
`

const Header = () => {
  return (
    <HeaderStyle>
      <HeaderWrapper>
        <Row vcenter height="100%" hlign="space-between">
          <HeaderCol width={'36%'}>
            <Align position={'left'}>
              <ul>
                  <li><Link to={'/about'}>ABOUT</Link></li>
                  <li><Link to={'/blog'}>BLOG</Link></li>
                  <li><Link to={'/careers'}>CAREERS</Link></li>
                  <li><Link to={'/works'}>WORKS</Link></li>
              </ul>
            </Align>
          </HeaderCol>
          <HeaderCol width={'33.33%'}>
            <Align position={'center'}>
              <Link to={'/'}><Img src={ Logo } className={'header-img'}/></Link>
            </Align>
          </HeaderCol>
          <HeaderCol width={'30%'}>
            <Align position={'right'}>
              <ul>
                <li style={{marginRight: 0}}><Link to={'/contact'}>CONTACT</Link></li>
              </ul>
            </Align>
          </HeaderCol>
        </Row>
      </HeaderWrapper>
    </HeaderStyle>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
